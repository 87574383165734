import * as React from 'react';
import Select, { components, SingleValueProps, OptionProps } from 'react-select';
import { useTranslation } from 'react-i18next';
import { Radio } from '@toolkit/ui';
import { DIRECTION, NOTIF_TYPE, TIME_WINDOW_TYPE, TRIP_DIRECTION } from '@src/shared/src/const/app';
import { OptionModel } from '@src/shared/src/models';
import { useDispatch, useSelector } from 'react-redux';
import { filterActions } from '@src/shared/src/actions';
import { isNil } from '@src/shared/src/util/general';
import { getTimeWindow } from '@src/shared/src/util/filters';
import { notify } from '@toolkit/util/app';
import { IRootState } from '@src/store';

type Props = {
  direction: DIRECTION;
  searchArrAt: Date;
  searchDepAt: Date;
};

export const SearchBarTimeWindowSelector: React.FC<Props> = ({
  direction,
  searchArrAt,
  searchDepAt,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedTime, setSelectedTime] = React.useState<TRIP_DIRECTION>(TRIP_DIRECTION.DEP);
  const searchTimeWindow = useSelector(
    (state: IRootState) => state.filters.tripTimeWindowUserFilter,
  );

  const getOptionMarkup = (opt: OptionModel) => (
    <div>
      <i className={`icon-watch_later`} /> {opt.label}
    </div>
  );
  const SingleValue = (props: SingleValueProps<OptionModel>) => (
    <components.SingleValue {...props}>{getOptionMarkup(props.data)}</components.SingleValue>
  );

  const Option = (props: OptionProps<OptionModel>) => {
    return <components.Option {...props}>{getOptionMarkup(props.data)}</components.Option>;
  };

  const windowOpts = (): OptionModel[] => [
    { value: TIME_WINDOW_TYPE.ANYTIME, label: t('SearchBarDateTimeSelector.option.anytime') },
    { value: TIME_WINDOW_TYPE.EARLY, label: t('SearchBarDateTimeSelector.option.early') },
    { value: TIME_WINDOW_TYPE.MORNING, label: t('SearchBarDateTimeSelector.option.morning') },
    { value: TIME_WINDOW_TYPE.AFTERNOON, label: t('SearchBarDateTimeSelector.option.afternoon') },
    { value: TIME_WINDOW_TYPE.EVENING, label: t('SearchBarDateTimeSelector.option.evening') },
    { value: TIME_WINDOW_TYPE.NIGHT, label: t('SearchBarDateTimeSelector.option.night') },
  ];

  const onChangeTimeWindow = (val: TIME_WINDOW_TYPE, direction: DIRECTION) => {
    const refDate: Date = direction === DIRECTION.OUTWARD ? searchDepAt : searchArrAt;
    console.log(val, searchDepAt, searchArrAt, refDate);
    if (!isNil(refDate)) {
      dispatch(filterActions.setTimeWindow(getTimeWindow(refDate, val), selectedTime, direction));
      // If the user changes the dep/arr time window the arr/dep should be resetted to ANYTIME
      dispatch(
        filterActions.setTimeWindow(
          {
            currentMax: -1,
            currentMin: -1,
            timeWindow: TIME_WINDOW_TYPE.ANYTIME,
          },
          selectedTime === TRIP_DIRECTION.DEP ? TRIP_DIRECTION.ARR : TRIP_DIRECTION.DEP,
          direction,
        ),
      );
    } else {
      notify('Please select a date first', NOTIF_TYPE.ERROR);
    }
  };

  return (
    <div className="tcp-search-bar-date-time-selector-window-time">
      <div className="tcp-search-bar-date-time-selector-window-time-direction">
        <div className="tcp-search-bar-date-time-selector-window-time-label">
          {t('searchBarDateTimeSelector.label.timeOf')}
        </div>
        <Radio
          id={`${direction}-dep`}
          value="dep"
          name={direction}
          label={t('searchBarDateTimeSelector.radio.label.departure')}
          checked={selectedTime === TRIP_DIRECTION.DEP}
          onChange={(value) => value && setSelectedTime(TRIP_DIRECTION.DEP)}
        />
        <Radio
          id={`${direction}-arr`}
          value="arr"
          name={direction}
          label={t('searchBarDateTimeSelector.radio.label.arrival')}
          checked={selectedTime === TRIP_DIRECTION.ARR}
          onChange={(value) => value && setSelectedTime(TRIP_DIRECTION.ARR)}
        />
      </div>

      <Select
        isSearchable={false}
        components={{ SingleValue, Option }}
        className="react-select"
        classNamePrefix="react-select"
        options={windowOpts()}
        value={windowOpts().find(
          (opt) =>
            opt.value ===
            (selectedTime === TRIP_DIRECTION.DEP
              ? searchTimeWindow[direction].dep.timeWindow
              : searchTimeWindow[direction].arr.timeWindow),
        )}
        onChange={(opt: OptionModel) =>
          onChangeTimeWindow(opt.value as TIME_WINDOW_TYPE, direction)
        }
      />
    </div>
  );
};
