import * as React from 'react';
import AsyncSelect from 'react-select/async';

import { t } from '@toolkit/util/i18n';
import { API_URL } from '@src/shared/src/const/api';
import { COSTCENTERS_RESULTS_PER_PAGE } from '@toolkit/const/app';
import { COST_CENTER_CATEGORIES } from '@src/shared/src/const/app';
import { WrappedFieldProps } from 'redux-form';
import { useSelector } from 'react-redux';
import { IRootState } from '@src/store';
import { format } from 'date-fns';
import { OptionModel } from '@src/shared/src/models';
import { isOnewaySearch } from '@src/shared/src/util/search';

type Props = WrappedFieldProps & {
  userIds: number[];
  required: boolean;
  category: COST_CENTER_CATEGORIES;
};

export const SelectWithPercentage: React.FC<Props> = props => {

  const [isInitLoad, setIsInitLoad] = React.useState(true)
  const [initValue, setInitValue] = React.useState(null)
  const search = useSelector((state: IRootState) => state.search.currentSearch);
  const validityDate: Date = isOnewaySearch(search) ? search.depAt : search.arrAt;

  const [percentValue, setPercentValue] = React.useState(props.input.value.percentageValue);
  const _onInputChange = (selectValue: any, percentageValue: number, selectLabel: string) => {
    props.input.onChange({
      selectValue,
      percentageValue,
      selectLabel,
    });
  };

  const getOptions = (input: string, callback: (options) => void) => {
    const userIdsParam = props.userIds.map((userId) => `user_ids[]=${userId}`).join('&');
    const params = [
      userIdsParam,
      `name=${input}`,
      `validity_date=${format(validityDate, 'yyyy-MM-dd')}`,
      `category=${props.category}`,
    ];
    return fetch(`${API_URL.COST_CENTER_ENTITIES}?${params.join('&')}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (isInitLoad && json.length === 1 && props.required) {
          const initialValue = json[0];
          setInitValue(initialValue);
          setIsInitLoad(false);
          _onInputChange(initialValue.id, 100, initialValue.name);
        }
        let mappedResults = json.map((entity) => ({
          label: entity.name,
          value: entity.id,
        }));
        if (mappedResults.length === COSTCENTERS_RESULTS_PER_PAGE) {
          mappedResults = [
            ...mappedResults,
            {
              label: t('selectInputCostCenter.option.label.find_more'),
              value: 0,
              isDisabled: true,
            },
          ];
        }
        console.log(mappedResults);
        callback(mappedResults);
      });
  };
  return (
    <div>
      <AsyncSelect
        {...props}
        value={
          (props.input.value
            ? { value: props.input.value.selectValue, label: props.input.value.selectLabel }
            : undefined) || initValue
        }
        onChange={(option: OptionModel) => {
          if (option) {
            _onInputChange(option.value, percentValue, option.label);
          } else {
            _onInputChange(null, null, null);
          }
        }}
        onBlur={() => props.input.onBlur(props.input.value)}
        defaultOptions={true}
        loadOptions={getOptions}
        noResultsText={t('tripsConn.title.noResults')}
        placeholder={t('global.select')}
      />
      <div className="is--bottom-field is--percentage">
        <input
          type="number"
          min="1"
          max="100"
          placeholder={t('global.percentage')}
          value={percentValue}
          onChange={(evt) => {
            const perVal = evt.target.value.replace(/\D/g, '');
            let validatedPercentage = Number(perVal) < 1 ? 1 : Number(perVal);
            validatedPercentage = validatedPercentage > 100 ? 100 : validatedPercentage;
            setPercentValue(validatedPercentage);
            _onInputChange(
              props.input.value.selectValue,
              validatedPercentage,
              props.input.value.selectLabel,
            );
          }}
        />
      </div>
      {props.meta.error && <div className="form-error-message">{props.meta.error}</div>}
    </div>
  );
};
