import * as React from 'react';
import { useDispatch } from 'react-redux';

// Utils
import { useTranslation } from 'react-i18next';
import { flatten, times } from '@src/shared/src/util/general';
import { searchActions } from '@src/shared/src/actions';
// Constants
import { DIRECTION } from '@src/shared/src/const/app';
// Models & Interfaces
import { OptionModel } from '@src/shared/src/models';
// Components
import Select, { components, SingleValueProps, OptionProps } from 'react-select';

type Props = {
  direction: DIRECTION;
};

export const SearchBarTimeSelector: React.FC<Props> = ({ direction }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [rentalTime, setRentalTime] = React.useState('09:00');

  React.useEffect(() => {
    if (rentalTime?.length) {
      dispatch(
        direction === DIRECTION.OUTWARD
          ? searchActions.setSearchDepRentalTime(rentalTime)
          : searchActions.setSearchArrRentalTime(rentalTime),
      );
    }
  }, []);

  const SingleValue = (props: SingleValueProps<OptionModel>) => (
    <components.SingleValue {...props}>{getOptionMarkup(props.data)}</components.SingleValue>
  );

  const Option = (props: OptionProps<OptionModel>) => {
    return <components.Option {...props}>{getOptionMarkup(props.data)}</components.Option>;
  };

  const timeOpts = () =>
    flatten(
      times((curr: number) => {
        const currPaded = String(curr).padStart(2, '0');
        return [
          { value: `${currPaded}:00`, label: `${currPaded}:00` },
          { value: `${currPaded}:30`, label: `${currPaded}:30` },
        ];
      }, 24),
    );

  const getOptionMarkup = (opt: OptionModel) => (
    <div>
      <i className={`icon-watch_later`} /> {opt.label}
    </div>
  );

  const onRentalTimeChange = (timeStr: string) => {
    setRentalTime(timeStr);

    dispatch(
      direction === DIRECTION.OUTWARD
        ? searchActions.setSearchDepRentalTime(timeStr)
        : searchActions.setSearchArrRentalTime(timeStr),
    );
  };

  return (
    <div className="tcp-search-bar-date-time-selector-rental-time">
      <Select
        className="react-select"
        classNamePrefix="react-select"
        isSearchable={false}
        components={{ SingleValue, Option }}
        options={timeOpts()}
        value={{ value: rentalTime, label: rentalTime }}
        placeholder={
          direction === DIRECTION.OUTWARD
            ? t('searchBarDateTimeSelector.rental.time.pickup.placeholder')
            : t('searchBarDateTimeSelector.rental.time.return.placeholder')
        }
        onChange={(opt: OptionModel) => onRentalTimeChange(opt.value)}
      />
    </div>
  );
};
