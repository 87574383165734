//TODO: refactor when we upgrade to react-select v2
import * as React from 'react';
import AsyncSelect from 'react-select/async';
import { t } from '@toolkit/util/i18n';
import { API_URL } from '@src/shared/src/const/api';
import { COSTCENTERS_RESULTS_PER_PAGE } from '@toolkit/const/app';
import { WrappedFieldProps } from 'redux-form';
import { COST_CENTER_CATEGORIES } from '@src/shared/src/const/app';
import { useSelector } from 'react-redux';
import { IRootState } from '@src/store';
import { format } from 'date-fns';
import { isOnewaySearch } from '@src/shared/src/util/search';

type Props = WrappedFieldProps & {
  mandatory: boolean;
  category: COST_CENTER_CATEGORIES;
};

export const SelectInputCostCenter: React.FC<Props> = (props) => {
  const [isInitLoad, setIsInitLoad] = React.useState(true)
  const [initValue, setInitValue] = React.useState(null)
  const search = useSelector((state: IRootState) => state.search.currentSearch);
  const validityDate: Date = isOnewaySearch(search) ? search.depAt : search.arrAt;

  const getOptions = (input: string, callback: (options) => void) => {
    const userIdsParam = props.userIds.map((userId) => `user_ids[]=${userId}`).join('&');
    console.log(input);
    const params = [
      userIdsParam,
      `name=${input}`,
      `validity_date=${format(validityDate, 'yyyy-MM-dd')}`,
      `category=${props.category}`,
    ];
    return fetch(`${API_URL.COST_CENTER_ENTITIES}?${params.join('&')}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (isInitLoad && json.length === 1 && props.mandatory) {
          setInitValue(json[0]);
          setIsInitLoad(false);
          props.input.onChange(json[0]);
        }

        let mappedResults = json;
        if (mappedResults.length === COSTCENTERS_RESULTS_PER_PAGE) {
          mappedResults = [
            ...mappedResults,
            { id: 0, name: t('selectInputCostCenter.option.label.find_more'), disabled: true },
          ];
        }
        callback(mappedResults);
      });
  };

  return (
    <div>
      <AsyncSelect
        {...props}
        autoload={true}
        value={props.input.value || initValue}
        onChange={(value) => props.input.onChange(value)}
        onBlur={() => props.input.onBlur(props.input.value)}
        loadOptions={getOptions}
        labelKey="name"
        valueKey="id"
        filterOptions={(options, filter, currentValues) => options}
        noResultsText={t('tripsConn.title.noResults')}
        placeholder={t('global.select')}
        isClearable={!props.mandatory}
      />
      {props.meta.error && <div className="form-error-message">{props.meta.error}</div>}
    </div>
  );
};
